import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import api from '../../../utils/auth';
import { fetchCsrfToken } from '../../../utils/csrf';
import Swal from 'sweetalert2';
import Switch from 'react-switch';
import ResizeObserver from 'resize-observer-polyfill';
import moment from 'moment';
import DOMPurify from 'dompurify';
import CustomModal from './CustomModal';
import styles from './BrowseSessions.module.css';
import Pagination from '../../../components/paginationControl';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; 
import useUserStore from '../../../store/useStudentStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from 'react-spinners/ClipLoader';

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

  const allSkills = [
    "Transportation", "Education", "Engineering", "Healthcare", "Business", "Finance", "Marketing", "Sales",
    "Services", "Public Services", "Repair", "Maintenance", "Aviation", "Accountancy", "Insurance", "Food and Beverage",
    "Hospitality", "Tourism", "Recreation", "Information Technology", "Management", "Software Development", "Human Resources",
    "Legal", "Social Work", "Art and Design", "Communications", "Construction", "Environmental", "Science", "Technology",
    "Pharmaceuticals", "Real Estate", "Retail", "Security", "Logistics", "Manufacturing", "Nonprofit", "Project Management",
    "Research", "Sports", "Digital Marketing", "Data Analysis", "Cybersecurity", "Machine Learning", "Event Management",
    "Customer Service", "Graphic Design", "Consulting", "Entrepreneurship", "Agricultural Science", "Biotechnology", "Blockchain",
    "Cloud Computing", "Compliance", "Corporate Strategy", "Creative Writing", "Crisis Management", "E-commerce", "Educational Technology",
    "Elder Care", "Electrical Engineering", "Energy Sector", "Fashion Design", "Film Production", "Game Development", "Government Relations",
    "Health Informatics", "Humanitarian Aid", "Interior Design", "International Business", "Journalism", "Labor Relations",
    "Leadership Development", "Market Research", "Materials Science", "Mental Health", "Mobile App Development", "Nanotechnology",
    "Network Security", "Occupational Therapy", "Patent Law", "Performance Arts", "Petroleum Engineering", "Philanthropy",
    "Photography", "Physical Therapy", "Political Science", "Public Health", "Quality Assurance", "Renewable Energy", "Risk Management",
    "Robotics", "Social Media Management", "Supply Chain Management", "Urban Planning", "Veterinary Medicine", "Video Production",
    "Virtual Reality", "Web Design", "Immigration"
  ]

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    height: '48px',
    minHeight: '48px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '48px',
    padding: '0 8px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '48px',
  }),
};

const BrowseSessions = () => {
  const { userData } = useUserStore();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [recommendedOnly, setRecommendedOnly] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1200);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false); // Loading state for the apply button
  const [hasApplied, setHasApplied] = useState(false); // Track if the student has applied to the session
  const defaultLandscape = `${process.env.PUBLIC_URL}/assets/images/defaultLandscape.jpg`;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  const updateScreenSize = () => {
    setIsScreenSmall(window.innerWidth < 1200);
    if (window.innerWidth >= 1200) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {
    filterSessions();
  }, [search, selectedSkills, selectedDate, recommendedOnly, sessions]);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        setLoading(true);
        const csrfToken = await fetchCsrfToken();
        const response = await api.get(`browse-sessions/verified-upcoming?page=${currentPage}&limit=${limit}`, {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });
  
        const sortedSessions = response.data.sessions.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA; // Sort asc by date
        });
  
        const sanitizedSessions = sortedSessions.map((session) => ({
          ...session,
          sanitizedSessionGoals: DOMPurify.sanitize(session.sessionGoals.join(', ')),
          sanitizedPrerequisites: DOMPurify.sanitize(session.prerequisites),
          sanitizedDescription: DOMPurify.sanitize(session.description),
        }));
  
        setSessions(sanitizedSessions);
        setFilteredSessions(sanitizedSessions);
        setSelectedSession(sanitizedSessions[0]);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching sessions:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchSessions();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSkillsChange = (selectedOptions) => {
    setSelectedSkills(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handleRecommendedChange = (checked) => {
    setRecommendedOnly(checked);
  };

  const filterSessions = () => {
    let filtered = sessions;
  
    if (search) {
      filtered = filtered.filter(session =>
        session.sessionName.toLowerCase().includes(search.toLowerCase())
      );
    }
  
    if (selectedSkills.length > 0) {
      filtered = filtered.filter(session =>
        selectedSkills.every(skill => session.skills.includes(skill))
      );
    }
  
    if (selectedDate) {
      const selectedDateString = selectedDate.toDateString();
      filtered = filtered.filter(session =>
        new Date(session.date).toDateString() === selectedDateString
      );
    }
  
    if (recommendedOnly) {
      filtered = filtered.filter(session =>
        session.impactScore > 80 || session.relevancyScore > 80 || session.networkingScore > 80
      );
    }
  
    // Ensure filtered sessions are sorted by date (latest first)
    const sortedFiltered = filtered.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA; // Sort asc by date
    });
  
    setFilteredSessions(sortedFiltered);
  };

  const handleApply = async () => {
    if (userData.freeGeneralSessions === 0) {
      Swal.fire({
        title: 'Upgrade Required',
        text: 'Upgrade your plan to register for more sessions.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: 'var(--primary-color)',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upgrade Now',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/student/payment');
        }
      });
    } else if (userData.freeGeneralSessions !== 0) {
      setButtonLoading(true); // Set loading state
      try {
        const csrfToken = await fetchCsrfToken();
        await api.post(`browse-sessions/register/${selectedSession._id}`, {}, {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });
        setHasApplied(true); // Set applied state

        Swal.fire({
          icon: 'success',
          title: 'Registered Successfully',
          text: 'You have successfully registered for the session.',
        });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          Swal.fire({
            icon: 'error',
            title: 'Already Registered',
            text: 'You have already registered for this session.',
          });
          setHasApplied(true); // Set applied state
        } else if (error.response && error.response.status === 403) {
          const errorMessage = error.response.data.msg;
          if (errorMessage.includes('Upgrade your account')) {
            Swal.fire({
              title: 'Upgrade Required',
              text: errorMessage,
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: 'var(--primary-color)',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Upgrade Now',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                navigate('/upgrade');
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Enrollment Not Allowed',
              text: errorMessage,
            });
          }
        } else {
          console.error('Error registering for session:', error);
          Swal.fire({
            icon: 'error',
            title: 'Registration Failed',
            text: 'An error occurred while trying to register for the session. Please try again later.',
          });
        }
      } finally {
        setButtonLoading(false); // Reset loading state
      }
    }
  };

  const handleViewMoreClick = (session) => {
    const sanitizedSessionGoals = DOMPurify.sanitize(session.sessionGoals.join(', '), {
      ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      ALLOWED_ATTR: ['href']
    });

    const sanitizedPrerequisites = DOMPurify.sanitize(session.prerequisites, {
      ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      ALLOWED_ATTR: ['href']
    });

    const sanitizedDescription = DOMPurify.sanitize(session.description, {
      ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      ALLOWED_ATTR: ['href']
    });

    setSelectedSession({
      ...session,
      sanitizedSessionGoals,
      sanitizedPrerequisites,
      sanitizedDescription
    });

    setIsModalOpen(true);
  };

  // const goToSettings = () => {
  //   navigate('/student/settings'); 
  // };

  const CircularProgressBar = ({ percentage, color }) => {
    const radius = 36;
    const strokeWidth = 4;
    const circumference = 2 * Math.PI * radius;
    const progress = percentage / 100 * circumference;

    return (
      <svg height="100" width="100">
        <circle
          className="text-gray-300"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          fill="none"
          cx="50"
          cy="50"
          r={radius}
        />
        <circle
          className="text-current"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="none"
          cx="50"
          cy="50"
          r={radius}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
        />
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          className="text-base font-bold fill-current"
        >
          {`${percentage}%`}
        </text>
      </svg>
    );
  };

  return (
    <div className="p-5 max-w-[1400px] mx-auto">
      <div className="text-2xl font-bold text-primary-color">Browse Sessions</div>
      <p className="mb-5 text-gray-600">Find our list of expert sessions across various fields</p>

      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Search for a session"
          className="w-full p-3 pr-12 rounded border border-border-color mb-4"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="flex flex-col md:flex-row gap-4 mb-5">
        <div className="flex flex-col md:flex-row items-center md:space-x-4 w-full">
          <div className="w-full md:w-1/3">
            <label className="block text-sm font-semibold text-[#107F9A] mb-2">Skills</label>
            <Select
              isMulti
              styles={customSelectStyles}
              className="w-full"
              value={selectedSkills.map(skill => ({ value: skill, label: skill }))}
              onChange={handleSkillsChange}
              options={allSkills.map(skill => ({ value: skill, label: skill }))}
              placeholder="Select Skills"
            />
          </div>
          <div className="w-full md:w-1/3">
            <label className="block text-sm font-semibold text-[#107F9A] mb-2">Date</label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              className="w-full p-2 border border-border-color rounded"
              placeholderText="Select Date"
            />
          </div>
          <div className="flex items-center w-full md:w-1/3 mt-4 md:mt-0">
            <label className="block text-sm font-semibold text-[#107F9A] mr-2">Recommended</label>
            <Switch
              onChange={handleRecommendedChange}
              checked={recommendedOnly}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
            />
          </div>
        </div>
      </div>

      <div className={`flex ${isScreenSmall ? 'flex-col' : 'flex-row'} gap-4`}>
        <div className={`${isScreenSmall ? 'flex-col' : 'flex-col'} w-full lg:w-1/3 gap-4 overflow-y-auto max-h-[80vh] m-auto mt-5`}>
          {loading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="border border-border-color p-4 mb-4 rounded-lg">
                <Skeleton height={20} width={150} />
                <Skeleton height={15} className="mt-2" />
                <Skeleton height={15} width={100} className="mt-2" />
                <Skeleton height={50} className="mt-2" />
              </div>
            ))
          ) : (
            <>
              {filteredSessions.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-full py-10 bg-gray-50 border border-gray-300 rounded-lg">
        <p className="text-xl font-semibold text-gray-700 mb-4 text-center">
            No Sessions Available
        </p>
        <p className="text-sm text-gray-500 text-center">
            Please check back later for upcoming sessions.
        </p>
    </div>
) : (
    filteredSessions.map((session) => (
        <div
            key={session._id}
            className={`relative border border-border-color p-4 mb-4 rounded-lg cursor-pointer hover:bg-gray-100 ${
                selectedSession && selectedSession._id === session._id ? 'bg-[#e9f7f9] border-[#107F9A]' : ''
            }`}
            onClick={() => !isScreenSmall && setSelectedSession(session)}
        >
            {(session.impactScore > 80 || session.relevancyScore > 80 || session.networkingScore > 80) && (
                <div className="absolute top-2 right-2 bg-red-500 text-white text-xs font-semibold py-1 px-2 rounded-lg">
                    Recommended
                </div>
            )}
                    
            <div className="text-sm text-gray-600 mb-2">
              {moment(session.availability.startLocal).format('MMMM DD, YYYY')}
            </div>
            <div className="font-semibold text-lg text-[#107F9A]">
                {session.sessionName}
            </div>
            <div className="text-sm text-gray-500">General Session</div>
            <p className="text-sm text-gray-700 mt-1">
                {session.instructorId.firstName} {session.instructorId.lastName}
            </p>

            <p className="text-sm text-gray-500">
                Impact Score: {session.impactScore}%
            </p>
            <p className="text-sm text-gray-500">
                Relevancy Score: {session.relevancyScore}%
            </p>
            <p className="text-sm text-gray-500">
                Networking Score: {session.networkingScore}%
            </p>

            {isScreenSmall && (
                <button
                    onClick={() => handleViewMoreClick(session)}
                    className="text-blue-500 mt-2 underline"
                >
                    View More
                </button>
            )}
        </div>
    ))
)}

              {!loading && totalPages > 1 && (
                <Pagination 
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </>
          )}
        </div>

        {selectedSession && !isScreenSmall && (
          <div className="flex-1 flex flex-col lg:flex-row gap-4">
            <div className="w-full">
              <div className="border border-border-color p-4 rounded-lg">
                <div className="flex flex-col lg:flex-row mb-4">
                  <div className="flex-shrink-0 w-full lg:w-64">
                    <img
                      src={selectedSession.imagePath || defaultLandscape}
                      alt="Session"
                      className="w-full h-64 object-cover rounded"
                    />
                  </div>
                  <div className="mt-4 lg:mt-0 lg:ml-4 flex-1">
                    <h2 className="text-2xl font-bold text-[#107F9A]">{selectedSession.sessionName}</h2>
                    <p className="text-sm text-gray-600">Date: {moment(selectedSession.availability.startLocal).format('MMMM DD, YYYY')}</p>
                    <p className="text-sm text-gray-600">{selectedSession.instructorId.firstName} {selectedSession.instructorId.lastName}</p>
                    <p className="text-sm text-gray-600">{moment(selectedSession.availability.startLocal).format('h:mm A')} - {moment(selectedSession.availability.endLocal).format('h:mm A')}</p>
                    <div className="mt-2">
                      {selectedSession.skills.map(skill => (
                        <span key={skill} className="inline-block bg-[#107F9A] text-white px-2 py-1 rounded-full text-xs mr-2 mb-2">{skill}</span>
                      ))}
                    </div>
                    <p className="text-sm text-gray-600 mt-2">Registered students: {selectedSession.registeredStudents.length}</p>
                    <div className="flex space-x-4 mt-4">
                      <div className="text-center">
                        <span className="block text-sm text-gray-600">Impact Score:</span>
                        <CircularProgressBar percentage={selectedSession.impactScore} color="var(--primary-color)" />
                      </div>
                      <div className="text-center">
                        <span className="block text-sm text-gray-600">Relevancy Score:</span>
                        <CircularProgressBar percentage={selectedSession.relevancyScore} color="var(--primary-color)" />
                      </div>
                      <div className="text-center">
                        <span className="block text-sm text-gray-600">Networking Score:</span>
                        <CircularProgressBar percentage={selectedSession.networkingScore} color="var(--primary-color)" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <h3 className="text-lg font-semibold text-gray-700">Session Goals:</h3>
                  <div
                    className={styles['session-content']}
                    dangerouslySetInnerHTML={{ __html: selectedSession.sanitizedSessionGoals }}
                  />
                  <h3 className="text-lg font-semibold text-gray-700 mt-2">Prerequisites:</h3>
                  <div
                    className={styles['session-content']}
                    dangerouslySetInnerHTML={{ __html: selectedSession.sanitizedPrerequisites }}
                  />
                  <h3 className="text-lg font-semibold text-gray-700 mt-2">Description:</h3>
                  <div
                    className={styles['session-content']}
                    dangerouslySetInnerHTML={{ __html: selectedSession.sanitizedDescription }}
                  />
                </div>
                <div className="mt-6 text-right">
                  <button
                    onClick={handleApply}
                    className={`bg-[#107F9A] text-white py-2 px-6 rounded hover:bg-[#106f8a] ${hasApplied ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={hasApplied}
                  >
                    {buttonLoading ? <ClipLoader color="#ffffff" size={20} /> : hasApplied ? 'Already Registered' : 'Apply Now'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {selectedSession && isScreenSmall && (
        <CustomModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
          <div className="p-4">
            <div className="mb-4">
              <h2 className="text-2xl font-bold text-[#107F9A]">{selectedSession.sessionName}</h2>
              <p className="text-sm text-gray-600">Date: {moment(selectedSession.availability.startLocal).format('MMMM DD, YYYY')}</p>
              <p className="text-sm text-gray-600">{selectedSession.instructorId.firstName} {selectedSession.instructorId.lastName}</p>
              <p className="text-sm text-gray-600">{moment(selectedSession.availability.startLocal).format('h:mm A')} - {moment(selectedSession.availability.endLocal).format('h:mm A')}</p>
            </div>
            <div className="mb-4">
              <img
                src={selectedSession.imagePath || defaultLandscape}
                alt="Session"
                className="w-full h-64 object-cover rounded"
              />
            </div>
            <div className="mb-4">
              {selectedSession.skills.map(skill => (
                <span key={skill} className="inline-block bg-[#107F9A] text-white px-2 py-1 rounded-full text-xs mr-2 mb-2">{skill}</span>
              ))}
            </div>
            <p className="text-sm text-gray-600 mb-4">Registered students: {selectedSession.registeredStudents.length}</p>
            <div className="flex space-x-4 mb-4">
              <div className="text-center">
                <span className="block text-sm text-gray-600">Impact Score:</span>
                <CircularProgressBar percentage={selectedSession.impactScore} color="var(--primary-color)" />
              </div>
              <div className="text-center">
                <span className="block text-sm text-gray-600">Relevancy Score:</span>
                <CircularProgressBar percentage={selectedSession.relevancyScore} color="var(--primary-color)" />
              </div>
              <div className="text-center">
                <span className="block text-sm text-gray-600">Networking Score:</span>
                <CircularProgressBar percentage={selectedSession.networkingScore} color="var(--primary-color)" />
              </div>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-gray-700">Session Goals:</h3>
              <div
                className={styles['session-content']}
                dangerouslySetInnerHTML={{ __html: selectedSession.sanitizedSessionGoals }}
              />
              <h3 className="text-lg font-semibold text-gray-700 mt-2">Prerequisites:</h3>
              <div
                className={styles['session-content']}
                dangerouslySetInnerHTML={{ __html: selectedSession.sanitizedPrerequisites }}
              />
              <h3 className="text-lg font-semibold text-gray-700 mt-2">Description:</h3>
              <div
                className={styles['session-content']}
                dangerouslySetInnerHTML={{ __html: selectedSession.sanitizedDescription }}
              />
            </div>
            <div className="mt-6 text-right">
              <button
                onClick={handleApply}
                className={`bg-[#107F9A] text-white py-2 px-6 rounded hover:bg-[#106f8a] ${hasApplied ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={hasApplied}
              >
                {buttonLoading ? <ClipLoader color="#ffffff" size={20} /> : hasApplied ? 'Already Registered' : 'Apply Now'}
              </button>
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default BrowseSessions;